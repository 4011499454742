<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="选择部分下架" prop="merchantIds">
        <el-select v-model="ruleForm.merchantIds" multiple placeholder="请选择">
          <el-option
            v-for="item in dropDownList_merchant"
            :key="item.usersMainsId"
            :label="item.shopName"
            :value="item.usersMainsId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="btn('cancel')">取消</el-button>
        <el-button type="primary" @click="btn('submit')">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  DisableToMerchant,
  DisableToMerchantGetInfoList,
  GetMerchantList,
} from "@/api/Company";
export default {
  //单独针对一部分商家下架某物流公司。先建立物流公司和商家的关联，然后被关联的商家，在发货时选择物流公司时，不展示被关联的物流公司
  props: {
    funcSucc: {
      type: Function,
    },
    companyId: {
      //公司id
      type: Number,
    },
  },
  data() {
    return {
      ruleForm: {
        merchantIds: [], //商家id集合
      },
      //商家下拉列表集合
      dropDownList_merchant: [{ usersMainsId: 1, shopName: "测试商品" }],
      rules: {
        //name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    //pageSize 改变时会触发
    btn(btnType) {
      switch (btnType) {
        case "cancel":
          this.funcSucc();
          console.log("取消");
          break;
        case "submit":
          console.log("提交");
          this.loading = true;
          let postData = {
            companyId: this.companyId,
            merchantId: this.ruleForm.merchantIds,
          };
          DisableToMerchant(postData)
            .then((res) => {
              if (res.code == 10000) {
                this.$message.success(res.msg);
                this.loading = false;
                this.funcSucc();
              } else {
                this.$message.error(res.msg);
                this.loading = false;
              }
            })
            .catch((err) => {
              this.loading = false;
            });
          this.funcSucc();
          break;
      }
    },
    loadData() {
      //得到商家
      GetMerchantList({ id: 1 })
        .then((res) => {
          if (res.code == 10000 && res.data) {
            this.dropDownList_merchant = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
      //得到已关联商家
      DisableToMerchantGetInfoList({ companyId: this.companyId })
        .then((res) => {
          if (res.code == 10000 && res.data) {
            this.ruleForm.merchantIds = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
  created: function () {
    console.log("初始化" + this.companyId); //undefined
    this.loadData();
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
</style>